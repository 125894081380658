import React from "react"
import { graphql } from "gatsby"
import SmallHero from "../components/layout/smallHero"
import Layout from "../components/layout/layout"
import "./mystyles.scss"
import { Helmet } from "react-helmet"

export default function Home({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>News & Agenda</title>
      </Helmet>
      <SmallHero title="News & Agenda" color="is-success" />
      <section className="section">
        <div className="container">
          {data.allMarkdownRemark.edges.reverse().map(({ node }) => (
            <div key={node.id}>
              <h3 className="title">{node.frontmatter.title}</h3>
              <div className="content has-text-justified">
                <div dangerouslySetInnerHTML={{ __html: node.html }} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "post" } } }) {
      edges {
        node {
          frontmatter {
            title
            date
          }
          html
          id
        }
      }
      totalCount
    }
  }
`
